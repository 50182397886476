@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
.light,
.light-theme {
  --blue-1: #fcfdff;
  --blue-2: #f6f9ff;
  --blue-3: #eaf2ff;
  --blue-4: #dbebff;
  --blue-5: #cae0ff;
  --blue-6: #b5d3ff;
  --blue-7: #9dc0ff;
  --blue-8: #79a7fe;
  --blue-9: #246bfd;
  --blue-10: #1e5fe5;
  --blue-11: #1d5ee4;
  --blue-12: #112e68;

  --blue-a1: #0055ff03;
  --blue-a2: #0055ff09;
  --blue-a3: #0062ff15;
  --blue-a4: #0072ff24;
  --blue-a5: #006aff35;
  --blue-a6: #0168ff4a;
  --blue-a7: #005cff62;
  --blue-a8: #0058fe86;
  --blue-a9: #0053fddb;
  --blue-a10: #004ae2e1;
  --blue-a11: #0049e1e2;
  --blue-a12: #001f5dee;

  --blue-contrast: #fff;
  --blue-surface: #f4f8ffcc;
  --blue-indicator: #246bfd;
  --blue-track: #246bfd;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --blue-1: oklch(99.4% 0.0025 262.3);
      --blue-2: oklch(98.2% 0.01 262.3);
      --blue-3: oklch(96% 0.0211 262.3);
      --blue-4: oklch(93.6% 0.0408 262.3);
      --blue-5: oklch(90.4% 0.0609 262.3);
      --blue-6: oklch(86.3% 0.0821 262.3);
      --blue-7: oklch(80.8% 0.1021 262.3);
      --blue-8: oklch(73.2% 0.1359 262.3);
      --blue-9: oklch(57.6% 0.2286 262.3);
      --blue-10: oklch(53.2% 0.2135 262.3);
      --blue-11: oklch(52.8% 0.2135 262.3);
      --blue-12: oklch(31.7% 0.1075 262.3);

      --blue-a1: color(display-p3 0.0235 0.349 1 / 0.012);
      --blue-a2: color(display-p3 0.0196 0.2667 0.8784 / 0.032);
      --blue-a3: color(display-p3 0.0078 0.3569 0.9529 / 0.079);
      --blue-a4: color(display-p3 0.0078 0.3686 0.9412 / 0.13);
      --blue-a5: color(display-p3 0.0078 0.3529 0.9412 / 0.193);
      --blue-a6: color(display-p3 0.0039 0.3412 0.9451 / 0.267);
      --blue-a7: color(display-p3 0.0039 0.298 0.9451 / 0.357);
      --blue-a8: color(display-p3 0.0039 0.2824 0.9373 / 0.487);
      --blue-a9: color(display-p3 0 0.2471 0.9451 / 0.777);
      --blue-a10: color(display-p3 0 0.2196 0.8353 / 0.808);
      --blue-a11: color(display-p3 0 0.2196 0.8314 / 0.812);
      --blue-a12: color(display-p3 0 0.0863 0.3255 / 0.902);

      --blue-contrast: #fff;
      --blue-surface: color(display-p3 0.9569 0.9725 1 / 0.8);
      --blue-indicator: oklch(57.6% 0.2286 262.3);
      --blue-track: oklch(57.6% 0.2286 262.3);
    }
  }
}

@layer utilities {
  /* .text-balance {
    text-wrap: balance;
  }
  .text-gradient {
    background: linear-gradient(to right, #34b1eb, #d75ff7, #ff726b, #ffb449);
    // background: linear-gradient(90deg, #0acd14, #e8f196, #f388be, #8facf1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  } */
}
.body {
  background-color: #fafafa;
}
.grecaptcha-badge {
  visibility: hidden;
}

.shadow-t {
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    0 -2px 4px -1px rgba(0, 0, 0, 0.06);
}
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.bg-stripe-gradient {
  background: repeating-linear-gradient(
    -55deg,
    #246bfd,
    #246bfd 8px,
    #4a8efc 8px,
    #4a8efc 13px
  );
}

.non-draggale {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.spin-wheel-animation {
  transform: rotate(var(--start-rotation-degrees));
}

.spin-wheel-animation.started-spinning {
  animation: spin-wheel var(--start-spinning-time)
      cubic-bezier(0.71, var(--spin-wheel-cb-arg), 0.96, 0.9) 0s 1 normal
      forwards running,
    continueSpin-wheel var(--continue-spinning-time) linear
      var(--start-spinning-time) 1 normal forwards running,
    stopSpin-wheel var(--stop-spinning-time) cubic-bezier(0, 0, 0.35, 1.02)
      var(--stop-spin-time-duration) 1 normal forwards running;
}
.shining-text {
  position: relative;
  display: inline-block;
  color: #fff;
  background: linear-gradient(60deg, #525150 0%, #242424 48%, #fbf1d3);
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: shining-text-kf 1s linear infinite;
}

.shake-horizontal {
  animation: horizontal-shake 5.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
}
@keyframes shining-text-kf {
  to {
    background-position: -200% center;
  }
}
@keyframes spin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes continueSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes stopSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--final-rotation-degrees));
  }
}
@keyframes horizontal-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-4px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(4px);
  }
}
